import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import './AdminDashboard.css';
import Calendar  from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { v4 as uuidv4 } from 'uuid'; // اضافه کردن uuid





const AdminDashboard = () => {
    // States for Product Form
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productImage, setProductImage] = useState(null);
    const [productDescription, setProductDescription] = useState('');
    const [productError, setProductError] = useState('');
    const [productSuccess, setProductSuccess] = useState('');
    
    // State to hold the productId for deletion
    const [productId, setProductId] = useState('');

    // States for Rental Property Form
    const [rentalName, setRentalName] = useState('');
    const [rentalPrice, setRentalPrice] = useState('');
    const [rentalLocation, setRentalLocation] = useState('');
    const [rentalMaxGuests, setRentalMaxGuests] = useState('');
    const [rentalAvailableFrom, setRentalAvailableFrom] = useState('');
    const [rentalAvailableUntil, setRentalAvailableUntil] = useState('');
    const [rentalDescription, setRentalDescription] = useState('');
    const [rentalError, setRentalError] = useState('');
    const [rentalSuccess, setRentalSuccess] = useState('');
    const [rentalImages, setRentalImages] = useState([{ imageUrl: '', order: 1 }]);

    // State to hold the rentalId for deletion
    const [rentalId, setRentalId] = useState('');

    // States for Collapse Sections
    const [openProductForm, setOpenProductForm] = useState(false);
    const [openRentalForm, setOpenRentalForm] = useState(false);
    const [openDeleteProductForm, setOpenDeleteProductForm] = useState(false);
    const [openDeleteRentalForm, setOpenDeleteRentalForm] = useState(false);
    const [isProductSubmitting, setIsProductSubmitting] = useState(false);
    const [isRentalSubmitting, setIsRentalSubmitting] = useState(false);
    
    // Handling product form submission
    const handleProductSubmit = async (e) => {
        e.preventDefault();
        setIsProductSubmitting(true);  // دکمه را غیرفعال می‌کنیم
        

        const tokenData = JSON.parse(localStorage.getItem('adminToken'));
        const token = tokenData ? tokenData.token : null;
        if (!token) {
            setProductError("برای اضافه کردن محصول باید وارد حساب کاربری ادمین شوید.");
            setIsProductSubmitting(false);
            return;
        }

        const formData = new FormData();
        formData.append('name', productName);
        formData.append('price', productPrice);
        formData.append('description', productDescription);
        // اضافه کردن نام یونیک به فایل تصویر محصول
        const uniqueImageName = `${uuidv4()}_${productName}.jpg`;
        formData.append('image', productImage, uniqueImageName);

        try {
            await axios.post(
                '/admin/product',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            setProductSuccess('محصول با موفقیت اضافه شد!');
            setIsProductSubmitting(false);  // دکمه را فعال می‌کنیم
            setProductError('');

            // Reset product form after success
            setProductName('');
            setProductPrice('');
            setProductImage(null);
            setProductDescription('');
        } catch (err) {
            setIsProductSubmitting(false);  // دکمه را فعال می‌کنیم
            setProductError('اضافه کردن محصول با شکست مواجه شد. لطفاً دوباره تلاش کنید.');
        }
    };

    // Handling rental form submission
    const handleRentalSubmit = async (e) => {
        e.preventDefault();
        setIsRentalSubmitting(true);  // دکمه را غیرفعال می‌کنیم

        const tokenData = JSON.parse(localStorage.getItem('adminToken'));
        const token = tokenData ? tokenData.token : null;
        if (!token) {
            setRentalError('برای اضافه کردن ملک اجاره‌ای باید وارد حساب کاربری ادمین شوید.');
            setIsRentalSubmitting(false);
            return;
        }

        const rentalData = {
            name: rentalName,
            price_per_night: rentalPrice,
            location: rentalLocation,
            max_guests: rentalMaxGuests,
            available_from: rentalAvailableFrom,
            available_until: rentalAvailableUntil,
            description: rentalDescription,
        };

        try {
            const rentalResponse = await axios.post(
                '/admin/rental',
                rentalData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const rentalId = rentalResponse.data.rental_id;

            for (const image of rentalImages) {
                const formData = new FormData();
                formData.append('image', image.imageUrl);
                formData.append('order', image.order);
                formData.append('rental_property_id', rentalId);
                const uniqueImageName = `${uuidv4()}_${rentalName}_image_${image.order}.jpg`;
                formData.append('image_name', uniqueImageName);

                await axios.post(
                    '/admin/rental/images',
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }

            setRentalSuccess('ملک اجاره‌ای با موفقیت اضافه شد!');
            setIsRentalSubmitting(false);  // دکمه را فعال می‌کنیم
            setRentalError('');

            // Reset rental form after success
            setRentalName('');
            setRentalPrice('');
            setRentalLocation('');
            setRentalMaxGuests('');
            setRentalAvailableFrom('');
            setRentalAvailableUntil('');
            setRentalDescription('');
            setRentalImages([{ imageUrl: '', order: 1 }]);
        } catch (err) {
            setIsRentalSubmitting(false);  // دکمه را فعال می‌کنیم
            setRentalError('اضافه کردن ملک اجاره‌ای با شکست مواجه شد. لطفاً دوباره تلاش کنید.');
        }
    };

    // Handling product and rental image changes
    const handleRentalImageChange = (index, event) => {
        const newImages = [...rentalImages];
        newImages[index].imageUrl = event.target.files[0];
        setRentalImages(newImages);
    };

    const handleRentalOrderChange = (index, event) => {
        const newImages = [...rentalImages];
        newImages[index].order = parseInt(event.target.value, 10);
        setRentalImages(newImages);
    };

    const addRentalImageField = () => {
        setRentalImages([...rentalImages, { imageUrl: '', order: rentalImages.length + 1 }]);
    };

    // Handling Delete Product
    const handleDeleteProduct = async () => {
        if (!productId) {
            setProductError("لطفاً شناسه محصول را وارد کنید.");
            return;
        }

        const tokenData = JSON.parse(localStorage.getItem('adminToken'));
        const token = tokenData ? tokenData.token : null;
        if (!token) {
            setProductError("برای حذف محصول باید وارد حساب کاربری ادمین شوید.");
            return;
        }

        try {
            await axios.delete(`/admin/product/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setProductSuccess('محصول با موفقیت حذف شد!');
            setProductId('')
            setProductError('');
        } catch (err) {
            setProductError('حذف محصول با شکست مواجه شد. لطفاً دوباره تلاش کنید.');
        }
    };

    // Handling Delete Rental
    const handleDeleteRental = async () => {
        if (!rentalId) {
            setRentalError("لطفاً شناسه ملک اجاره‌ای را وارد کنید.");
            return;
        }

        const tokenData = JSON.parse(localStorage.getItem('adminToken'));
        const token = tokenData ? tokenData.token : null;
        if (!token) {
            setRentalError('برای حذف ملک اجاره‌ای باید وارد حساب کاربری ادمین شوید.');
            return;
        }

        try {
            await axios.delete(`/admin/rental/${rentalId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setRentalSuccess('ملک اجاره‌ای با موفقیت حذف شد!');
            setRentalId('')
            setRentalError('');
        } catch (err) {
            setRentalError('حذف ملک اجاره‌ای با شکست مواجه شد. لطفاً دوباره تلاش کنید.');
        }
    };

    return (
        <div className="container">
  
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <title>پنل مدیریت</title>
            </Helmet>
            <h1>پنل مدیریت</h1>

            <div className="form-section">
                <button onClick={() => setOpenProductForm(!openProductForm)}>
                    {openProductForm ? 'بستن فرم اضافه کردن محصول' : 'اضافه کردن محصول'}
                </button>
                {openProductForm && (
                    <form onSubmit={handleProductSubmit}>
                        <input
                            type="text"
                            placeholder="نام محصول"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            required
                        />
                        <input
                            type="number"
                            placeholder="قیمت محصول"
                            value={productPrice}
                            onChange={(e) => setProductPrice(e.target.value)}
                            required
                        />
                        <input
                            type="file"
                            onChange={(e) => setProductImage(e.target.files[0])}
                            required
                        />
                        <textarea
                            placeholder="توضیحات محصول"
                            value={productDescription}
                            onChange={(e) => setProductDescription(e.target.value)}
                            required
                        />
                        <button type="submit" disabled={isProductSubmitting}>ثبت محصول</button>
                        {productError && <p className="error">{productError}</p>}
                        {productSuccess && <p className="success">{productSuccess}</p>}
                    </form>
                )}
            </div>

            <div className="form-section">
                <button onClick={() => setOpenRentalForm(!openRentalForm)}>
                    {openRentalForm ? 'بستن فرم اضافه کردن ملک اجاره‌ای' : 'اضافه کردن ملک اجاره‌ای'}
                </button>
                {openRentalForm && (
                    <form onSubmit={handleRentalSubmit}>
                        <input
                            type="text"
                            placeholder="نام ملک اجاره‌ای"
                            value={rentalName}
                            onChange={(e) => setRentalName(e.target.value)}
                            required
                        />
                        <input
                            type="number"
                            placeholder="قیمت به ازای شب"
                            value={rentalPrice}
                            onChange={(e) => setRentalPrice(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="موقعیت"
                            value={rentalLocation}
                            onChange={(e) => setRentalLocation(e.target.value)}
                            required
                        />
                        <input
                            type="number"
                            placeholder="حداکثر مهمان‌ها"
                            value={rentalMaxGuests}
                            onChange={(e) => setRentalMaxGuests(e.target.value)}
                            required
                        />
                        <h3>تاریخ شروع</h3>
                        <Calendar
                            calendar={persian}
                            locale={persian_fa}
                            value={rentalAvailableFrom}
                            onChange={setRentalAvailableFrom}
                            required

                        />
                        <h3>تاریخ اتمام</h3>
                        <Calendar
                            calendar={persian}
                            locale={persian_fa}
                            value={rentalAvailableUntil}
                            onChange={setRentalAvailableUntil}
                            required

                        />
                        <textarea
                            placeholder="توضیحات"
                            value={rentalDescription}
                            onChange={(e) => setRentalDescription(e.target.value)}
                            required
                        />
                        {rentalImages.map((image, index) => (
                            <div key={index}>
                                <input
                                    type="file"
                                    onChange={(e) => handleRentalImageChange(index, e)}
                                    required
                                />
                                <input
                                    type="number"
                                    value={image.order}
                                    onChange={(e) => handleRentalOrderChange(index, e)}
                                    placeholder="ترتیب"
                                    required
                                />
                            </div>
                        ))}
                        <button type="button" onClick={addRentalImageField}>
                            افزودن تصویر جدید
                        </button>
                        <button type="submit" disabled={isRentalSubmitting}>ثبت ملک اجاره‌ای</button>
                        {rentalError && <p className="error">{rentalError}</p>}
                        {rentalSuccess && <p className="success">{rentalSuccess}</p>}
                    </form>
                )}
            </div>

            <div className="form-section">
                <button onClick={() => setOpenDeleteProductForm(!openDeleteProductForm)}>
                    {openDeleteProductForm ? 'بستن فرم حذف محصول' : 'حذف محصول'}
                </button>
                {openDeleteProductForm && (
                    <div>
                        <input
                            type="text"
                            placeholder="شناسه محصول"
                            value={productId}
                            onChange={(e) => setProductId(e.target.value)}
                            required
                        />
                        <button onClick={handleDeleteProduct}>حذف محصول</button>
                        {productError && <p className="error">{productError}</p>}
                        {productSuccess && <p className="success">{productSuccess}</p>}
                    </div>
                )}
            </div>

            <div className="form-section">
                <button onClick={() => setOpenDeleteRentalForm(!openDeleteRentalForm)}>
                    {openDeleteRentalForm ? 'بستن فرم حذف ملک اجاره‌ای' : 'حذف ملک اجاره‌ای'}
                </button>
                {openDeleteRentalForm && (
                    <div>
                        <input
                            type="text"
                            placeholder="شناسه ملک اجاره‌ای"
                            value={rentalId}
                            onChange={(e) => setRentalId(e.target.value)}
                            required
                        />
                        <button onClick={handleDeleteRental}>حذف ملک اجاره‌ای</button>
                        {rentalError && <p className="error">{rentalError}</p>}
                        {rentalSuccess && <p className="success">{rentalSuccess}</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminDashboard;
