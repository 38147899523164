import React, { useEffect,useContext  } from 'react';
import './Store.css'; // اطمینان حاصل کنید که این CSS برای استایل‌دهی محصولات موجود است
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';
import { ProductContext } from '../context/ProductContext';
import { throttle } from 'lodash';
const Store = () => {
    const { products, loading, hasMore, setPage, loadProducts,setProducts ,errorLoading,setErororLoading} = useContext(ProductContext); 
    const { isAuthenticated } = useContext(AuthContext);
    

    const formatPrice = (price) => {
        return new Intl.NumberFormat('fa-IR').format(price) + ' تومان';
    };

    useEffect(() => {
        if (products.length === 0) { // فقط در صورتی که محصولات خالی است، بارگذاری کنید
            loadProducts(1);
        }
    }, [products, loadProducts]); // فقط بارگذاری در صورت خالی بودن محصولات

    const loadMoreProducts = () => {
        console.log()
        if (hasMore) {
            const nextPage = Math.ceil(products.length / 10) + 1; // فرض کنید که هر صفحه 10 محصول دارد
            setPage(nextPage);
            loadProducts(nextPage);
        }
    };
    useEffect(() => {
        // تابع throttled برای بهینه‌سازی اسکرول
        const handleScroll = throttle(() => {
          const scrollTop = window.scrollY;
          const windowHeight = window.innerHeight;
          const documentHeight = document.documentElement.scrollHeight;
    
          if (scrollTop + windowHeight >= documentHeight - 20 && !loading) {
            loadMoreProducts();
            console.log("کاربر به انتهای صفحه نزدیک شده است!");
          }
        }, 200); // اجرا هر 200 میلی‌ثانیه
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          handleScroll.cancel(); // برای پاکسازی تابع throttled
        };
    }, [loading]);  // به روز رسانی useEffect هنگام تغییر مقدار loading
    const handleLike = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!'); // نمایش پیغام برای کاربر
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/products/${productId}/like`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // ارسال توکن در هدر
                'Content-Type': 'application/json', // نوع محتوا
            },
        });
        const data = await response.json();
        if (response.ok) {
            setProducts((prevProducts) =>
                prevProducts.map((product) => 
                    product.id === productId ? {
                        ...product,
                        likes: data.likes,
                        dislikes: data.dislikes,
                        likedByUser: !product.likedByUser, // تغییر وضعیت لایک
                        dislikedByUser: false // دیس‌لایک باید به false برگردد
                    } : product
                )
            );
        } else {
            console.error('Error liking product:', data);
        }
    };
    
    const handleDislike = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!'); // نمایش پیغام برای کاربر
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/products/${productId}/dislike`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // ارسال توکن در هدر
                'Content-Type': 'application/json', // نوع محتوا
            },
        });
        const data = await response.json();
        if (response.ok) {
            console.log(data)
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === productId ? {
                        ...product,
                        dislikes: data.dislikes,
                        likes: data.likes,
                        dislikedByUser: !product.dislikedByUser, // تغییر وضعیت دیس‌لایک
                        likedByUser: false // لایک باید به false برگردد
                    } : product
                )
            );
        } else {
            console.error('Error disliking product:', data);
        }
    };

    
    return (
        
        <div className="store-page-wrapper">
            
            {errorLoading && (
            <div className="error-message">
                <p>خطا در بارگزاری داده ها</p>
                <button className='store-page-try-agin' onClick={() => { setErororLoading(false); loadProducts(); }}>تلاش دوباره</button>
            </div>
            )}
            <div className="store-page">
                {!errorLoading && <h1>محصولات محبوب</h1>}
                <div className="store-page__product-list">
                    {products.length > 0 ? (
                        products.map((product) => (
                            <div key={product.id} className="store-page__product-card">
                                <Link to={`/product/${product.id}`} >
                                    <img src={product.image} alt={product.name} className="store-page__product-image" />
                                    <p className="store-page__product-id">کد: {product.id}</p>
                                    <h2 className="store-page__product-name">{product.name}</h2>
                                </Link>
                                <p className="store-page__product-price">قیمت: {formatPrice(product.price)} تومان</p>
                                <div className="store-page__likes-dislikes">
                                    <span onClick={() => handleLike(product.id)} style={{ color: product.likedByUser ? '#4CAF50' : '#000' }}>
                                        <i className="fas fa-thumbs-up"></i> {product.likes}
                                    </span>
                                    <span onClick={() => handleDislike(product.id)} style={{ color: product.dislikedByUser ? '#f44336' : '#000' }}>
                                        <i className="fas fa-thumbs-down"></i> {product.dislikes}
                                    </span>
                                </div>
                                <button className="store-page__buy-button">خرید</button>
                            </div>
                        ))
                    ) : (
                        !errorLoading && hasMore &&<p>در حال بارگذاری محصولات...</p>
                    )}
                </div>
                {loading && !errorLoading &&<p>در حال بارگذاری...</p>}
                {!hasMore && <p>محصول دیگری وجود ندارد.</p>}
                {hasMore && !errorLoading &&(
                    <div className="store-page__load-more" onClick={loadMoreProducts}>
                        <i className="fas fa-plus-circle"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Store;
